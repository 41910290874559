const useWindowClient = () => {
	const isMobileClient = useMediaQuery("(max-width: 767px)");
	const isDesktopClient = useMediaQuery("(min-width: 768px)");

	return {
		isMobileClient,
		isDesktopClient
	};
};

export default useWindowClient;
